import React from "react";

const ReceiptHeader = ({ office, subs }) => {
  return (
    <div className="">
      <div className="header">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            paddingBottom: "2px",
            borderBottom: "solid 2px black",
          }}
        >
          <img className="py-2" src="../images/logo.jpg" alt="Intitution Logo" style={{ maxWidth: 80 }} />
          <div style={{ paddingTop: 10, fontSize: 12 }}>
            <h1
              style={{
                marginTop: 0,
                marginBottom: 3,
                fontSize: 15,
              }}
            >
              ADAMU TAFAWA BALEWA COLLEGE OF EDUCATION, KANGERE
            </h1>
            <div className="text-uppercase">DIRECTORATE OF UNDERGRADUATE STUDIES</div>

            <div className="text-uppercase">in affliation TO</div>
            <div className="text-uppercase">Bayero University Kano</div>
            <div className="text-uppercase">MAIN CAMPUS LOCATION ALONG BAUCHI-GOMBE ROAD, BAUCHI STATE</div>
          </div>

          <img className="py-2" src="../images/buk.jpeg" alt="Intitution Logo" style={{ maxWidth: 80 }} />
        </div>
      </div>

      {/* <div className="lettertohead">
        <div className="">
          <div>
            <b style={{ color: "red" }}>PROVOST:</b>
          </div>
          <b className="text-uppercase">
            Dr. Buteh DAUDA Sanda <small style={{ fontSize: 10 }}>MICCON, NCSN</small>
          </b>
          <div>HND, MSc, PhD (ATBU)</div>
          <div>provost@coekangere.edu.ng</div>
        </div>
        <div className="">
          <div>
            <b style={{ color: "red" }}>REGISTRAR:</b>
          </div>
          <div className="text-uppercase">
            <b>
              Alh. Sambo Aliyu, <small style={{ fontSize: 10 }}>MNIM, FIICA</small>
            </b>
          </div>
          <div>BA (HONS) (BUK)</div>
          <div>registrar@coekangere.edu.ng</div>
        </div>
      </div>
      <div className="officename">
        <div>{office !== "" && office}</div>
        <small>{subs && subs}</small>
      </div> */}
    </div>
  );
};

export default ReceiptHeader;

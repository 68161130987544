import React from "react";
import CourseRegistration from "../../components/CourseRegistration";
import Loader from "../../components/Loader";
import TuitionPayment from "../../components/TuitionPayment";
import Alerts from "../../helpers/Alerts";
import { axiosget } from "../../helpers/httprequest";
import { toastr } from "toastr";
import { contextapi } from "../../helpers/contextapi";
import is_null from "../../helpers/is_null";
import { useNavigate } from "react-router-dom";
import swal from "../../helpers/swal";

const MainRegistration = () => {
  const [loading, setloading] = React.useState(true);
  const [status, setstatus] = React.useState(undefined);
  const context = React.useContext(contextapi);
  const navigate = useNavigate();
  React.useEffect(() => {
    (async () => {
      try {
        setloading(false);
        const req = await axiosget(`ms_course_registration/my`);
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        const { stage } = req.data;
        setstatus(stage);
      } catch ({ message }) {
        toastr.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const _show = () => {
    try {
      const { _id: id } = context?.values?.student;
      if (is_null(id)) {
        toastr.warn(`Sorry, system experience some technical problem, Please logout and login again`);
        return;
      }
      navigate(`/print-registration/${id}`);
    } catch ({ message }) {
      swal("error", message, setloading, false);
    }
  };
  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <p className="card-title">STUDENT MODULE REGISTRATION</p>
                  <div className="row">
                    <div className="col-12">
                      {loading ? (
                        <Loader message="Please wait system loading" />
                      ) : (
                        <>
                          {status === "registered" ? (
                            <>
                              <Alerts
                                type="info"
                                title="Registration Completed"
                                message="You have completed your modules registration for the active trimester"
                              />
                              <hr />
                              <div className="text-right">
                                <button className="btn btn-primary" onClick={_show}>
                                  Print Course Form
                                </button>
                              </div>
                            </>
                          ) : (
                            <>{status === "paid" ? <CourseRegistration /> : <TuitionPayment />}</>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainRegistration;

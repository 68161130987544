import React from "react";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import { axiosget } from "../../helpers/httprequest";
import is_money from "../../helpers/is_money";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";

const IHavePaid = ({ total }) => {
  const [showmodal, setshowmodal] = React.useState(false);
  const [name, setname] = React.useState(undefined);
  const [loading, setloading] = React.useState(false);
  const navigate = useNavigate();
  const _verify = async () => {
    try {
      setloading(true);
      if (is_null(name)) {
        swal("warning", "Please enter the transaction code", setloading, false);
        return;
      }
      const req = await axiosget(`ms_transaction/verify-new/${name}`);
      if (req.status !== 200) {
        throw new Error("Sorry something happened, please try again later");
      }
      const { type, message } = req.data;
      if (type === "success") {
        swal(type, message, () => navigate("/new-registration"), false);
        return;
      }
      swal(type, message, () => setloading, false);
      setloading(false);
    } catch ({ message }) {
      swal("error", message, setloading, false);
    }
  };
  return (
    <div className="text-right cursor-pointer my-2">
      <ReactModal isOpen={showmodal} onRequestClose={() => setshowmodal(false)} className="ReactModal">
        <div className="modal-dialog " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center">VERIFY YOUR PAYMENT</h5>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="exampleInputEmail2">Enter your Remita RRR Code (e.g.: 688982500000)</label>
                <input
                  type="number"
                  className="form-control form-control-lg"
                  id="exampleInputEmail2"
                  onChange={(e) => setname(e.target.value)}
                  placeholder="Enter your Remita RRR Code (e.g.: 688982500000)"
                />
              </div>
            </div>
            <div className="modal-footer">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Please wait...
                </button>
              ) : (
                <button type="button" className="btn btn-primary" onClick={_verify}>
                  Verify Payment
                </button>
              )}
              <button type="button" className="btn btn-danger" onClick={() => setshowmodal(false)}>
                Close
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
      <button className="btn text-primary text-bolder" onClick={() => setshowmodal(true)}>
        I have already paid {is_money(total)}
      </button>
    </div>
  );
};

export default IHavePaid;

import React from "react";
import { useParams } from "react-router-dom";
import is_null from "../../helpers/is_null";
import { axiosget } from "../../helpers/httprequest";
import Loader from "../../components/Loader";
import PrintHeader from "../../Print/PrintHeader";
import PrintStudentCardProfile from "../../Print/PrintStudentCardProfile";

const PrintRegistration = () => {
  const param = useParams();
  const [loading, setloading] = React.useState(false);
  const [student, setstudent] = React.useState(undefined);
  const [session, setsession] = React.useState(undefined);
  const [programme, setprogramme] = React.useState(undefined);
  const [registration, setregistration] = React.useState(undefined);
  const { id } = param;
  if (is_null(id)) {
    window.close();
  }
  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await axiosget(`ms_course_registration/print-lecturer/${id}`);
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        const { student: info, session: trimester, programme: prog, registration: reg } = req.data;
        setstudent(info);
        setsession(trimester);
        setprogramme(prog);
        setregistration(reg);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(registration[0]?.register);
  return (
    <div className="mainPrintContainer">
      {loading ? (
        <Loader />
      ) : (
        <>
          {session && <PrintHeader session={session} />}
          <>
            {student && <PrintStudentCardProfile student={student} session={session} programme={programme} title="COURSE REGISTRATION FORM (CRF)" />}
          </>
          <>
            {registration && (
              <>
                {registration?.map((v) => (
                  <div key={`show_div_${v?._id}`} className="printStudentRegistration">
                    <div style={{ flex: 1, textAlign: "center", textTransform: "uppercase", fontSize: 15 }}>
                      {v.level} {v.semester}
                    </div>
                    <div>
                      <table className="styled-tables">
                        <thead>
                          <tr>
                            <th>SN</th>
                            <th>Code</th>
                            <th>Name</th>
                            <th>Credit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {v?.register?.map((r, i) => (
                            <tr key={`show_tr_${i}`}>
                              <th>{i + 1}</th>
                              <td>{r?.courseinfo?.code}</td>
                              <td>{r?.courseinfo?.name}</td>
                              <td>{r?.credit}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div style={{ marginTop: 5, fontSize: 14, padding: 10 }}>
                      <div style={{ fontSize: 24, fontWeight: "bolder" }}>
                        TOTAL CREDIT UNIT REGISTERED FOR SEMESTER =
                        {registration !== null && registration[0]?.register?.map((v) => v.credit).reduce((a, b) => a + b, 0)}
                      </div>
                      <div style={{ marginTop: 25, fontSize: 14, padding: 10 }}>
                        <div style={{ marginTop: 5, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                          <div style={{ textAlign: "center", fontSize: 19, borderTop: "solid 2px black", borderBottom: "solid 2px black" }}>
                            ATTESTATION/DECLARATION <br />
                          </div>

                          <div style={{ marginTop: 20, borderBottom: "dotted 1px black" }}></div>
                          <div style={{ textAlign: "center" }}>
                            {student?.firstname} {student?.middlename} {student?.lastname} {student?.studentid}
                          </div>

                          <div
                            style={{
                              marginTop: 20,
                              textAlign: "center",
                              fontSize: 19,
                              borderTop: "solid 2px black",
                              borderBottom: "solid 2px black",
                            }}
                          >
                            ​CERTIFICATION BY HEAD OF DEPARTMENT <br />
                          </div>
                          <div style={{ marginTop: 20, borderBottom: "dotted 1px black" }}></div>
                          <div style={{ textAlign: "center" }}>HEAD OF DEPARTMENT</div>

                          <div
                            style={{
                              marginTop: 20,
                              textAlign: "center",
                              fontSize: 19,
                              borderTop: "solid 2px black",
                              borderBottom: "solid 2px black",
                            }}
                          >
                            ​CERTIFICATION BY DIRECTOR UNDEGRADUATE STUDIES <br />
                          </div>
                          <div style={{ marginTop: 20, borderBottom: "dotted 1px black" }}></div>
                          <div style={{ textAlign: "center" }}>DIRECTOR UNDERGRADUATE STUDIES</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </>
        </>
      )}
    </div>
  );
};

export default PrintRegistration;

import React from "react";
import "./index.css";

const PrintStudentCardProfile = ({ student, programme, session, title }) => {
  return (
    <div style={{ fontSize: 12, display: "flex", flexDirection: "row", padding: "0px 10px", borderBottom: "solid 4px black" }}>
      <div className="upper" style={{ flexGrow: 1 }}>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
          <div className="muted item">Student ID:</div>
          <div className="itemValue upper">{student.studentid}</div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
          <div className="muted item">Student Full Name:</div>
          <div className="itemValue upper">
            {student.firstname} {student.middlename} {student.lastname}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
          <div className="muted item">School:</div>
          <div className="itemValue">{programme?.department?.college?.name}</div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
          <div className="muted item">Department:</div>
          <div className="itemValue">{programme?.department?.name}</div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
          <div className="muted item">Programme:</div>
          <div className="itemValue">{programme.name}</div>
        </div>

        <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
          <div className="muted item">Session:</div>
          <div className="itemValue">{session?.name}</div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
          <div className="muted item">Report Title:</div>
          <div className="itemValue">{title}</div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
          <div className="muted item">Printed Date:</div>
          <div className="itemValue">{new Date().toISOString()}</div>
        </div>
      </div>
      <div>
        <img src={student?.photo?.link} className="PassportPhotograph" alt="profile" />
      </div>
    </div>
  );
};

export default PrintStudentCardProfile;

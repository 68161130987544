import React from "react";
import LoadAttendance from "../../components/LoadAttendance";
import Loader from "../../components/Loader";
import { axiosget } from "../../helpers/httprequest";

const MainAttendance = () => {
  const [loading, setloading] = React.useState(true);
  const [data, setdata] = React.useState(undefined);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_course/student");
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        console.log(req.data);
        setdata(req.data);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <p className="card-title text-uppercase">
                    My Semester Attendance <hr />
                  </p>
                  {loading ? (
                    <div className="text-center">
                      <Loader message={"Please wait while we load your modules"} />
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-12">
                        <div className="table-responsive">
                          <table id="example" className="display expandable-table w-100 table table-hover table-bordered">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Code</th>
                                <th>Title</th>
                                <th>Trimester</th>
                                <th>Credit</th>
                                <th>Level</th>
                                <th>Semester</th>
                                <th>Held</th>
                                <th>Attended</th>
                                <th>Percentage</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.map((v, i) => (
                                <tr>
                                  <th>{i + 1}</th>
                                  <td>{v.courseinfo?.code}</td>
                                  <td>{v.courseinfo?.name}</td>
                                  <td>{v.result?.code}</td>
                                  <td>{v.courseinfo?.credit}</td>
                                  <td>{v.level}</td>
                                  <td>{v.semester}</td>
                                  <LoadAttendance session={v.session} course={v.course} />
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainAttendance;

import React from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { contextapi } from "../../helpers/contextapi";
import { axiosget, axiospost } from "../../helpers/httprequest";
import swal from "../../helpers/swal";
import is_money from "../../helpers/is_money";
import IHavePaid from "../../components/IHavePaid";
import { APIKEY, MerchantId } from "../../components/Keys";
import CryptoJS from "crypto-js";
import is_null from "../../helpers/is_null";

const NewFee = () => {
  const [loading, setloading] = React.useState(true);

  const [loadRRR, setloadRRR] = React.useState(false);
  const [serviceid, setserviceid] = React.useState(0);
  const [total, settotal] = React.useState(0);
  const [complete, setcomplete] = React.useState(0);
  const [scholar, setscholar] = React.useState(0);
  const [list, setlist] = React.useState(undefined);
  // const [session, setsession] = React.useState(undefined);
  const context = React.useContext(contextapi);
  const navigate = useNavigate();
  const { student: user } = context.values;
  React.useEffect(() => {
    (async () => {
      try {
        const trx = await axiosget("ms_transaction/new");
        if (trx?.data !== null) {
          setTimeout(() => navigate("/dashboard"), 500);
          return;
        } else {
          const req = await axiosget("ms_session/active");
          if (req.data?.type) {
            throw new Error(req.data?.message);
          }
          // setsession(req.data);
          const ex = _extractor(user);
          const fee_group = await axiospost(`ms_tuition_fee_items/new`, {
            programme: ex.programme,
            level: ex.level,
            student: user?._id,
            semester: ex.semester,
            indigene: ex.stateoforigin,
          });
          const { check, serviceId, scholar: scholarship } = fee_group.data;
          let sum = await check?.map((r) => r.amount)?.reduce((a, b) => a + b, 0);
          setcomplete(sum);
          if (!is_null(scholarship) && Number.parseFloat(scholarship?.percentage) >= 1) {
            const { percentage } = scholarship;
            const percentile = Number.parseFloat(percentage) / 100;
            setscholar(percentage);
            sum = sum * percentile;
          }
          settotal(sum);
          setserviceid(serviceId);
          setlist(check);
        }
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _extractor = (user) => {
    const { level, semester, stateoforigin, programme } = user;
    const splits = String(level).split(" ");
    let lev = Number.parseInt(splits[0]);
    let sem = semester === "First Semester" ? 1 : 2;
    let origin = stateoforigin === "Bauchi" ? 2 : 3;
    return { level: lev, semester: sem, stateoforigin: origin, programme };
  };

  const _generateRRR = async () => {
    try {
      setloadRRR(true);
      //check if the student alreay has an RRR code
      const ex = _extractor(user);
      const cishrrr = await axiospost(`ms_transaction/rrr`, {
        programme: ex.programme,
        level: ex.level,
        semester: ex.semester,
      });

      if (cishrrr?.data?.isrrr !== undefined && cishrrr?.data?.isrrr === false) {
        const serviceTypeId = serviceid;
        const d = new Date();
        const orderId = d.getTime();
        const merchantId = MerchantId;
        const apiKey = APIKEY;
        const amount = total;

        var apiHash = CryptoJS.SHA512(merchantId + serviceTypeId + orderId + amount + apiKey);

        const dataRRR = {
          serviceTypeId: serviceTypeId,
          amount: amount,
          orderId: orderId,
          payerName: `${user.firstname} ${user.middlename}  ${user.lastname}`,
          payerEmail: user?.emailaddress,
          payerPhone: user?.phonenumber,
          description: `Tuition Fee Payment ${user.StudentID} in ${user.level} ${user.semester} `,
        };

        const headerRRR = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `remitaConsumerKey=${merchantId},remitaConsumerToken=${apiHash}`,
          },
          method: "POST",
          body: JSON.stringify(dataRRR),
        };
        fetch("https://login.remita.net/remita/exapp/api/v1/send/api/echannelsvc/merchant/api/paymentinit", headerRRR)
          .then((r) => r.text())
          .then(async (r) => {
            const removeF = JSON.parse(r.substring(r.indexOf("{"), r.lastIndexOf("}") + 1));
            if (removeF?.RRR === undefined) {
              swal("error", removeF.statusMessage, undefined, false);
              return;
            }
            console.log(removeF);
            const rrr = removeF?.RRR;
            const fetch = await axiospost("ms_transaction/generated_rrr", {
              level: ex.level,
              semester: ex.semester,
              list: list,
              amount: total,
              serviceId: serviceid,
              rrr: removeF.RRR,
              data: dataRRR,
            });
            if (fetch?.status !== 200) {
              swal("error", "Sorry, system could not store your RRR Code, please try and generate another RRR code", setloadRRR, false);
              return;
            }
            if (fetch?.data?.type === "error") {
              swal("error", fetch?.data?.message, setloadRRR, false);
              return;
            }
            swal(
              "success",
              `<p>Your transaction RRR has been generated</p>
              <br/>
              <h1>${rrr}</h1>
              <br/>
              <p>Please copy it as store it somewhere safe</p>`,
              setloadRRR,
              false
            );
          });
      } else {
        swal(
          "success",
          `<p>Your transaction RRR has already been generated</p>
          <br/>
          <h1>${cishrrr?.data?.rrr}</h1>
          <br/>
          <p>Please copy it as store it somewhere safe</p>`,
          setloadRRR,
          false
        );
        return;
      }
    } catch ({ message }) {
      swal("error", message, setloadRRR, false);
    }
  };

  return (
    <div className="main-panel">
      <div className="content-wrapper d-flex align-items-center auth px-0">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="auth-form-light text-left py-1 px-4 px-sm-5">
                <div className="brand-logo text-center">
                  <img src="images/logo.jpg" alt="logo" />
                  <img src="images/buk.jpeg" style={{ maxWidth: 100 }} alt="BUK logo" />
                </div>
                <div className="text-left">
                  <h4>ADAMU TAFAWA BALEWA COLLEGE OF EDUCATION, KANGERE</h4>
                  <h5>You are required to pay for the following items:</h5>
                  <h4>
                    NAMES: {String(user.firstname).toLocaleUpperCase()} {user.middlename} {user.lastname}
                  </h4>
                  <h4>STUDENT ID: {user.studentid}</h4>
                </div>
                <div className="pt-3">
                  {loading ? (
                    <div className="text-center py-5">
                      <Loader />
                      <p>Please wait while we verify your payment</p>
                    </div>
                  ) : (
                    <div className="mt-3">
                      <div className="table-responsive mb-3">
                        <table className="table table-hover table-bordered" id="example">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Description</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {list?.map((v, i) => (
                              <tr key={v._id}>
                                <th>{i + 1}</th>
                                <th>{v.label}</th>
                                <th>{is_money(v.amount)}</th>
                              </tr>
                            ))}
                            {scholar && (
                              <>
                                <tr>
                                  <th>{list?.length + 1}</th>
                                  <th>Expected Total </th>
                                  <th>{is_money(complete)}</th>
                                </tr>
                                <tr>
                                  <th>{list?.length + 2}</th>
                                  <th>Scholarship</th>
                                  <th>{scholar}% off</th>
                                </tr>
                              </>
                            )}
                          </tbody>
                          <tfoot>
                            <tr>
                              <th colSpan={2}> Total</th>
                              <th>{is_money(total)}</th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                      <div className="my-3">
                        <IHavePaid total={total} />
                        {loadRRR ? (
                          <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">Generating RRR Code</button>
                        ) : (
                          <button onClick={_generateRRR} className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">
                            Generate RRR for {is_money(total)} to continue registration
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFee;

import React from "react";
import { contextapi } from "../../helpers/contextapi";
import { axiosget } from "../../helpers/httprequest";
import { BarChart } from "@mui/x-charts/BarChart";

const MainDashboard = () => {
  const context = React.useContext(contextapi);
  const { student } = context.values;
  const [data, setdata] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      try {
        const { student: info } = context.values;
        const trx = await axiosget(`ms_dashboard/student/${info?._id}`);
        if (trx?.data?.type) {
          throw new Error(trx.data?.message);
        }
        setdata(trx.data);
      } catch ({ message }) {}
    })();
  }, []);
  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 grid-margin">
                      <div className="row">
                        <div className="col-12 col-xl-12 mb-4 mb-xl-0">
                          <h3 className="font-weight-bold">Welcome {student?.firstname}...</h3>
                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="card" style={{ background: "blue", borderRadius: "15px", padding: "15px", color: "white" }}>
                        <div className="card-content text-center">
                          <h1>{data?.regs}</h1>
                          <hr />
                          <h2 className="text-uppercase">Registered Courses</h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card" style={{ background: "blue", borderRadius: "15px", padding: "15px", color: "white" }}>
                        <div className="card-content text-center">
                          <h1>{data?.passed}</h1>
                          <hr />
                          <h2 className="text-uppercase">Courses Passed</h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card" style={{ background: "blue", borderRadius: "15px", padding: "15px", color: "white" }}>
                        <div className="card-content text-center">
                          <h1>{data?.failed}</h1>
                          <hr />
                          <h2 className="text-uppercase">Courses Failed</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-10">
                      <div className="p-3">Academic Result Statistic</div>
                      <hr />
                      {data && (
                        <BarChart
                          series={[{ data: [data?.a, data?.b, data?.c, data?.d, data?.e, data?.f] }]}
                          height={350}
                          xAxis={[{ data: ["A", "B", "C", "D", "E", "F"], scaleType: "band" }]}
                          margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainDashboard;

import React from "react";
import "./index.css";
import { appHost } from "../../helpers/uri";

const PrintHeader = () => {
  return (
    <div className="printHeaderContainer">
      <div className="brand-logo text-center">
        <img src={`${appHost}images/logo.jpg`} style={{ maxWidth: 90, paddingRight: 10 }} alt="logo" />
      </div>
      <div style={{ flexGrow: 1, textAlign: "center", textTransform: "uppercase" }}>
        <h1 style={{ fontSize: 15 }}>ADAMU TAFAWA BALEWA COLLEGE OF EDUCATION, KANGERE</h1>
        <h1 style={{ fontSize: 12 }}>DIRECTORATE OF UNDERGRADUATE STUDIES</h1>
        <h1 style={{ fontSize: 12 }}>IN AFFILIATION TO </h1>
        <h1 style={{ fontSize: 12 }}>BAYERO UNIVERSITY, KANO</h1>
        <h4 style={{ fontSize: 12 }}>Main Campus Location Along Bauchi-Gombe Road, Bauchi State</h4>
      </div>
      <div>
        <img src={`${appHost}images/buk.jpeg`} style={{ maxWidth: 70 }} alt="BUK logo" />
      </div>
    </div>
  );
};

export default PrintHeader;

import React from "react";
import { useParams } from "react-router-dom";
import ReceiptHeader from "./ReceiptHeader";
import "./letter.css";
import { axiosget } from "../../helpers/httprequest";
import Loader from "../../components/Loader";
import is_null from "../../helpers/is_null";
import is_money from "../../helpers/is_money";
import { QRCode } from "react-qrcode";
import { appHost } from "../../helpers/uri";

const PaymentReceipt = () => {
  const { id } = useParams();
  const [loading, setloading] = React.useState(false);
  const [data, setdata] = React.useState(null);
  const [scholar, setscholar] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const req = await axiosget(`ms_transaction/receipt/${id}`);
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        setdata(req?.data?.find);
        setscholar(req?.data?.scholar);
        setloading(false);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    // eslint-disable-next-line
  }, []);
  const fullAmount = !is_null(data)
    ? data?.items?.reduce(function (acc, obj) {
        return acc + obj.amount;
      }, 0)
    : 0;
  let allAmount = fullAmount;
  if (scholar) {
    const percent = Number.parseFloat(scholar?.percentage) / 100;
    allAmount = allAmount * percent;
  }
  return (
    <div>
      <ReceiptHeader office={`Office of the Bursar`} subs={`Payment receipts`} />
      {loading ? (
        <Loader />
      ) : (
        <div className="" style={{ marginTop: 10 }}>
          {is_null(data) ? (
            <div>No payment payment information found.</div>
          ) : (
            <div style={{ fontSize: 12 }}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div>
                  <div className="paymentInfo">
                    Registration Number: <b>{!is_null(data) && data?.student?.studentid}</b>
                  </div>
                  <div className="paymentInfo">
                    Full Name:{" "}
                    <b>
                      {!is_null(data) && data?.student?.firstname} {!is_null(data) && data?.student?.middlename}{" "}
                      {!is_null(data) && data?.student?.lastname}
                    </b>
                  </div>
                  <div className="paymentInfo">
                    School: <b>{!is_null(data) && data?.student?.programme?.department?.college?.name}</b>
                  </div>
                  <div className="paymentInfo">
                    Department: <b>{!is_null(data) && data?.student?.programme?.department?.name}</b>
                  </div>
                  <div className="paymentInfo">
                    Programme: <b>{!is_null(data) && data?.student?.programme?.name}</b>
                  </div>
                </div>
                <div>{/* <QRCode value={`${appHost}payment-receipt/${data?._id}`} /> */}</div>
                <div>
                  <div className="paymentInfo">
                    Session: <b>{!is_null(data) && data?.session?.name}</b>
                  </div>
                  <div className="paymentInfo">
                    Invoice ID (RRR): <b>{!is_null(data) && data?.rrr}</b>
                  </div>
                  <div className="paymentInfo">
                    Amount Paid: <b>{is_money(fullAmount)}</b>
                  </div>
                  <div className="paymentInfo">
                    Status: <b>{!is_null(data) && data?.status ? "Paid" : "Not paid"}</b>
                  </div>
                  <div className="paymentInfo">
                    Processed Date: <b>{!is_null(data) && data?.createdAt}</b>
                  </div>
                </div>
              </div>
              <hr />
              <div style={{ paddingLeft: 7, fontSize: 12, textTransform: "uppercase", fontWeight: "bolder" }}>
                <div style={{ textAlign: "center" }}>{data?.session?.name} UGS STUDENT PAYMENT RECEIPT</div>
              </div>
              <table className="styled-tables">
                <thead>
                  <tr>
                    <th>SN</th>
                    <th>Item Description</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {!is_null(data) &&
                    data?.items?.map((r, i) => (
                      <tr key={r._id}>
                        <th>{i + 1}</th>
                        <td>{r.label}</td>
                        <td>{is_money(r.amount)}</td>
                      </tr>
                    ))}
                  {scholar && (
                    <>
                      <tr>
                        <th>{data?.items?.length + 1}</th>
                        <td>Expected Amount</td>
                        <td>{is_money(fullAmount)}</td>
                      </tr>
                      <tr>
                        <th>{data?.items?.length + 2}</th>
                        <td>Discount/Scholarship</td>
                        <td>{scholar?.percentage}%</td>
                      </tr>
                    </>
                  )}
                </tbody>

                <tfoot>
                  <tr>
                    <td></td>
                    <td>Total</td>
                    <td>{is_money(allAmount)}</td>
                  </tr>
                </tfoot>
              </table>
              <div style={{ fontSize: 12, paddingLeft: 10, paddingRight: 10 }}>
                <div style={{ marginTop: 4, textAlign: "center", lineHeight: 2 }}>
                  The sum of <b>{is_money(allAmount)}</b> was paid to Adamu Tafawa Balewa College of Education Kangere, Bauchi in respect of{" "}
                  {data?.session?.name} UGS Full - Time Registration service via Remita Using RRR: {data?.rrr} <br />
                  FEES ARE NOT REFUNDABLE AFTER PAYMENT
                </div>
                <div style={{ fontSize: 12 }}>
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <div style={{ textAlign: "center", borderTop: "solid 2px black", borderBottom: "solid 2px black" }}>
                      ATTESTATION/DECLARATION <br />
                    </div>

                    <div style={{ marginTop: 20, borderBottom: "dotted 1px black" }}></div>
                    <div style={{ textAlign: "center" }}>
                      {data?.student?.firstname} {data?.student?.middlename} {data?.student?.lastname} {data?.student?.studentid}
                    </div>

                    <div style={{ textAlign: "center", borderTop: "solid 2px black", borderBottom: "solid 2px black" }}>
                      ​CERTIFICATION BY THE BURSAR <br />
                    </div>
                    <div style={{ marginTop: 20, borderBottom: "dotted 1px black" }}></div>
                    <div style={{ textAlign: "center" }}>HEAD OF BURSARY DEPARTMENT</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PaymentReceipt;

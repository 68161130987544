import React from "react";
import Loader from "../../components/Loader";
import SelectStatus from "../../components/SelectStatus";
import { axiosget, axiospost } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import swal from "../../helpers/swal";
import moment from "../../helpers/moment";

const MainChangeProgramme = () => {
  const [loading, setloading] = React.useState(true);
  const [submit, setsubmit] = React.useState(false);
  const [data, setdata] = React.useState(undefined);
  const [programme, setprogramme] = React.useState(undefined);
  const [application, setapplication] = React.useState(undefined);
  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget("ms_programme/active");
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        const list = await Promise.all(req.data?.map((v) => ({ name: `${v.code} ${v.name}`, value: v._id })));
        setdata(list);
        const app = await axiosget("ms_course_change/my");
        if (app.data?.type) {
          throw new Error(app.data?.message);
        }
        console.log(app.data);
        setapplication(app.data);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _status = (num) => {
    if (num === 0) {
      return "Pending, Awaiting Receiving HOD";
    }
    if (num === 1) {
      return "Your HOD has approved";
    }
    if (num === 2) {
      return "Management Approved";
    }
    if (num === 3) {
      return "Receiving HOD Reject";
    }
    if (num === 4) {
      return "Management Rejected";
    }
    return;
  };

  const _save = async () => {
    try {
      setsubmit(true);
      if (is_null(programme)) {
        swal("error", "Sorry you need to fill the form properly", setsubmit, false);
        return;
      }
      const req = await axiospost("ms_course_change/apply", { to: programme });
      if (req.status !== 200) {
        throw new Error("Sorry something went wrong");
      }
      const { type, message } = req.data;
      if (type === "success") {
        swal(type, message, setsubmit, true);
        return;
      }
      swal(type, message, setsubmit, false);
    } catch ({ message }) {
      swal("error", message, setsubmit, false);
    }
  };
  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <p className="card-title text-uppercase">
                    Change of Programme
                    <hr />
                  </p>
                  {loading ? (
                    <div className="text-center">
                      <Loader message={"Please wait while we load your modules"} />
                    </div>
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-md-8">
                          <SelectStatus setitem={setprogramme} options={data} label="Select Programme You Wish to Transfer To" />
                        </div>
                        <div className="col-md-4 mt-4">
                          {submit ? (
                            <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">Please wait...</button>
                          ) : (
                            <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" onClick={_save}>
                              Apply Change of Programme
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-12 table-responsive">
                          <table id="example" className="table table-hover table-bordered w-100">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Status</th>
                                <th>Date</th>
                                <th>Last Change</th>
                              </tr>
                            </thead>
                            <tbody>
                              {application?.map((v, i) => (
                                <tr>
                                  <th>{i + 1}</th>
                                  <td>
                                    {v.frominfo.code} {v.frominfo.name}
                                  </td>
                                  <td>
                                    {v.toinfo.code} {v.toinfo.name}
                                  </td>
                                  <td>{_status(v.approvalstatus)}</td>
                                  <td>{moment(v.createdAt)} ago</td>
                                  <td>{moment(v.updatedAt)} ago</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainChangeProgramme;

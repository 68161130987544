import React from "react";
import Loader from "../../components/Loader";
import Navbar from "../../components/Navbar";
// import { contextapi } from "../../helpers/contextapi";
import { axiosget } from "../../helpers/httprequest";
import is_null from "../../helpers/is_null";
import Alerts from "../../helpers/Alerts";
import moment from "../../helpers/moment";

const Notifications = () => {
  const [data, setdata] = React.useState(undefined);
  const [loading, setloading] = React.useState(true);
  React.useEffect(() => {
    (async () => {
      setloading(true);
      try {
        const req = await axiosget("ms_notification/seen");
        if (req.status !== 200 || !is_null(req.data?.type)) {
          throw new Error("Sorry, something went wrong...");
        }
        const d = req.data?.length ? req.data : undefined;
        setdata(d);
      } catch ({ message }) {
        console.warn(message);
      }
      setloading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-scroller">
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="container-fluid">
            <Navbar />
            <div style={{ marginTop: 30 }}>
              <div className="card ">
                <p className="p-4 card-title">
                  STUDY MATERIAL
                  <hr />
                </p>
                <div className="card-body">
                  {loading ? (
                    <Loader message={"Please wait system is loading  your notifications"} />
                  ) : (
                    <ul className="icon-data-list w-100">
                      <>
                        {!is_null(data) &&
                          data?.map((v) => (
                            <li>
                              <div className="d-flex">
                                <img src={v?.staff?.photo} alt="user" />
                                <div>
                                  <p className="text-info mb-1 text-uppercase">
                                    {v?.staff?.firstname} {v?.staff?.middlename} {v?.staff?.lastname} {` | `} {v?.results?.code} {` `}
                                    {v?.results?.name}
                                  </p>
                                  <p className="mb-0">{v?.post}</p>
                                  <small>{moment(v?.createdAt)} ago</small>
                                </div>
                              </div>
                            </li>
                          ))}
                      </>
                      <>
                        {is_null(data) && (
                          <Alerts
                            type={"info"}
                            title={"No Notification to Show"}
                            message="Sorry, there are no notifications to show for the time been"
                          />
                        )}
                      </>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
